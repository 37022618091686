
export default {
  props: {
    avatar: {
      type: String,
      default: ''
    },
    username: {
      type: [String, Number],
      default: null
    }
  },
  data: () => {
    return {
      BASE_URL: process.env.BASE_URL,
      nav: [
        {
          title: 'nav-home',
          icon: 'bx-home',
          url: '',
          target: '',
          local: false,
          active: false
        }
      ],
      chat_modal: false
    }
  },
  methods: {
    activeMenu (val) {
      const path = this.$route.path.split('.')
      if (path && path.length > 0) {
        const clean_path = path[0].split('/').join('')
        if (clean_path === val) {
          return true
        }
      }
      return false
    },
    handleTitle (val) {
      return this.$t(val)
    },
    goMarketplace () {
      window.open(process.env.BASE_MARKETPLACE_URL, '_self')
    }
  }
}
