
export default {
  props: {
    memberPosition: {
      default: "",
      type: String,
    },
    item: {
      default: null,
    },
    params_id: {
      default: null,
    },
  },
  data() {
    return {
      dropdownMenu: [
        {
          name: "Edit",
          icon: "bx-edit",
          action: "edit",
          style: "",
          role: ["owner", "admin", "moderator"],
        },
        {
          name: "Leave",
          icon: "bx-exit",
          action: "leave",
          style: "",
          role: ["admin", "member", "moderator"],
        },
        {
          name: `Turn notification ${
            +this.$store.state.auth.user.member.receive_notification
              ? "off"
              : "on"
          }`,
          icon: "bx-bell",
          action: "toggleNotification",
          style: "",
          role: ["owner", "admin", "member", "moderator"],
        },
        {
          name: "Remove",
          icon: "bx-trash",
          action: "delete",
          style: "color: red",
          role: ["owner"],
        },
      ],
    };
  },
  watch: {
    "$store.state.auth.user.member.receive_notification"(val) {
      this.dropdownMenu[2].name = `Turn notification ${+val ? "off" : "on"}`;
    },
  },
  methods: {
    async toggleNotification(item) {
      await this.$store
        .dispatch("community/toggleCommunityNotification", item.member_data)
        .then((response) => {
          this.$store.commit("auth/SET_MEMBER", response.body);
          this.$message({
            message: "Berhasil mengubah pengaturan notifikasi",
            type: "success",
          });
        })
        .catch((err) => {
          this.$message({
            message: "Gagal mengubah pengaturan notifikasi",
            type: "error",
          });
        });
    },
    handleDropdown(val, item) {
      switch (val) {
        case "edit":
          this.editCommunity(item);
          break;
        case "delete":
          this.confirmDelete(item);
          break;
        case "report":
          this.reportCommunity(item);
          break;
        case "leave":
          this.submitLeaveCommunity();
          break;
        case "toggleNotification":
          this.toggleNotification(item);
          break;
      }
    },
    async submitLeaveCommunity() {
      const formData = new FormData();
      formData.append("status", "deleted");

      const payload = {
        id: this.item.id_member,
        formData,
      };
      await this.$store
        .dispatch("community/postSubmitLeaveCommunity", payload)
        .then((res) => {
          window.location.reload();
        })
        .catch((err) => {
          this.$message.error({
            message: "Terjadi kesalahan, coba kembali dalam beberapa menit",
            offset: 50,
            duration: 3500,
          });
        });
    },
    reportCommunity(val) {
      this.report_id = val.id;
      this.active_report = true;
    },
    editCommunity(val) {
      this.$router.push(`/add?id=${this.params_id}`);
    },
    confirmDelete() {
      this.$confirm("Apa kamu yakin mau menghapus Community ini?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
        center: true,
      }).then(() => {
        this.deleteCommunity();
      });
    },
    async deleteCommunity() {
      await this.$store
        .dispatch("community/deleteCommunity", this.params_id)
        .then(() => {
          this.$message({
            message: "Berhasil delete community",
            type: "success",
          });
          this.$router.push("/community");
        })
        .catch(() => {
          this.$message({
            message: "Gagal delete community",
            type: "error",
          });
        });
    },
    async deleteCommunity() {
      const data = {
        param_id: this.params_id,
        param_slug: "",
      };
      await this.$store
        .dispatch("public/getCommunityDetail", data)
        .then((res) => {})
        .catch((err) => {
          throw new Error(err);
        });
    },
  },
};
