
export default {
  props: ["communityID", "communitySlug"],
  data: () => ({
    dropdownMenu: [
      {
        name: "Edit",
        icon: "bx-edit",
        action: "edit",
        style: "",
        role: ["owner", "admin"],
      },
      {
        name: "Leave Group",
        icon: "bx-exit",
        action: "leave",
        style: "color: #FD3018",
        role: ["admin", "member", "moderator"],
      },
      {
        name: `Turn notification`,
        icon: "bx-bell",
        action: "toggleNotification",
        style: "",
        role: ["owner", "admin", "member", "moderator"],
      },
      {
        name: "Delete",
        icon: "bx-trash",
        action: "delete",
        style: "color: #FD3018",
        role: ["owner"],
      },
    ],
  }),

  mounted() {
    this.dropdownMenu[2].name = `Turn notification ${
      +this.$store.state.auth.user.member.receive_notification ? "off" : "on"
    }`;
  },
  watch: {
    "$store.state.auth.user.member.receive_notification"(val) {
      this.dropdownMenu[2].name = `Turn notification ${+val ? "off" : "on"}`;
    },
  },
  methods: {
    handleDropdown(val) {
      switch (val) {
        case "edit":
          this.$router.push("/add?id=" + this.communityID);
          break;
        case "delete":
          this.confirmDelete();
          break;
        case "leave":
          this.submitLeaveCommunity();
          break;
        case "toggleNotification":
          this.toggleNotification();
          break;
      }
    },
    submitLeaveCommunity() {
      this.$setMemberStatus(this.$store.state.auth.user.member.id, "deleted") //plugins/api-member.js
        .then((res) => {
          if (res.status) {
          }
          this.$message({
            message: "Berhasil meninggalkan komunitas",
            type: "success",
            duration: 2000,
            onClose: () => {
              window.location.reload();
            },
          });
        })
        .catch((err) => {
          this.$message({
            message: "Gagal meninggalkan komunitas",
            type: "error",
            duration: 2000,
          });
        });
    },
    // async deleteCommunity(){
    //   const  res = await
    // },

    async deleteCommunity() {
      await this.$store
        .dispatch("community/deleteCommunity", this.communityID)
        .then(() => {
          this.$message({
            message: "Berhasil delete community",
            type: "success",
          });
          setTimeout(() => {
            this.$router.push("/discover");
          }, 500);
        })
        .catch(() => {
          this.$message({
            message: "Gagal delete community",
            type: "error",
          });
        });
    },
    confirmDelete() {
      this.$confirm("Apa kamu yakin mau menghapus Community ini?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
        center: true,
      }).then(() => {
        this.deleteCommunity();
      });
    },
    toggleNotification() {
      this.$setToggleNotification()
        .then((response) => {
          if (response.status) {
          }
          this.$message({
            message: "Berhasil mengubah pengaturan notifikasi",
            type: "success",
          });
        })
        .catch((err) => {
          console.info(err.message);
          this.$message({
            message: "Gagal mengubah pengaturan notifikasi",
            type: "error",
          });
        });
    },
    handleShare(val, item) {
      if (!window) {
        return;
      }
      const base = window.location.host;
      const dateNow = new Date().getTime();
      const username = this.$store.state.auth.user.myProfile.slug
        ? this.$store.state.auth.user.myProfile.slug
        : "_" + Math.floor(Math.random() * 10000 + 1);
      const url = `https://link.modoc.id/${this.communitySlug}?slug=${username}&_utm=${dateNow}`;
      switch (val) {
        case "fb":
          return window.open(
            "http://www.facebook.com/sharer/sharer.php?u=" +
              url +
              "&_ch=facebook",
            "_blank"
          );
        case "wa":
          return window.open(
            "https://api.whatsapp.com/send?text=" + url + "&_ch=whatsapp",
            "_blank"
          );
        case "tw":
          return window.open(
            "https://twitter.com/share?url=" + url + "&_ch=twitter",
            "_blank"
          );
        case "in":
          return window.open(
            "http://www.linkedin.com/shareArticle?mini=true&url=" +
              url +
              "&_ch=linkedin",
            "_blank"
          );
      }
    },
  },
};
